import type { AppRouteModule } from "/@/router/types";

import { LAYOUT } from "/@/router/constant";
import { t } from "/@/hooks/web/useI18n";

const system: AppRouteModule = {
  path: "/people",
  name: "People",
  component: LAYOUT,
  redirect: "/people/employee",
  meta: {
    orderNo: 40,
    // icon: 'ion:aperture-outline',
    /** 2022/3/14
     *作者:pzt
     *内容:
     **/
    // icon: "ant-design:usergroup-add-outlined",
    title: t("routes.menus.people.people"),
    roles: ["admin", "People",
      "Account",
      "AccountDetail",
      "ContractPeople",
      "Contract",
      "Dept"
    ]
  },
  children: [
    {
      path: "enterprise",
      name: "Enterprise",
      meta: {
        title: t("routes.menus.people.enterprise"),
        roles: ["admin", "Dept"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/people/enterprise/index.vue")
    },
    {
      path: "contract",
      name: "Contract",
      meta: {
        title: t("routes.menus.people.contract"),
        ignoreKeepAlive: false,
        roles: ["admin", "Contract"]
      },
      component: () => import("/@/views/people/contract/index.vue")
    },
    {
      path: "contractDetail/:id/:name",
      name: "ContractDetail",
      meta: {
        hideMenu: true,
        title: t("routes.menus.people.contractDetail"),
        roles: ["admin", "Contract"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/people/contract"
      },
      component: () => import("/@/views/people/contract/ContractDetail.vue")
    },
    {
      path: "employee",
      name: "Employee",
      meta: {
        title: t("routes.menus.people.employee"),
        ignoreKeepAlive: false,
        roles: ["admin", "Account"]
      },
      component: () => import("/@/views/people/employee/index.vue")
    },
    {
      path: "employeeDetail/:id/:name",
      name: "EmployeeDetail",
      meta: {
        hideMenu: true,
        title: t("routes.menus.people.employeeDetail"),
        roles: ["admin", "Account"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/people/employee"
      },
      component: () => import("/@/views/people/employee/Detail.vue")
    },
    {
      path: "insuranceHistory",
      name: "InsuranceHistory",
      meta: {
        hideMenu: true,
        title: t("routes.menus.people.employeeDetail"),
        roles: ["admin", "Account"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/people/employee"
      },
      component: () => import("/@/views/people/employee/insuranceHistory/Detail.vue")
    },
    {
      path: "contractPeople",
      name: "ContractPeople",
      meta: {
        realPath: "/people/contractPeople",
        title: t("routes.menus.people.contractPeople"),
        roles: ["admin", "ContractPeople"],
        dynamicLevel: 1,
        carryParam: true,
        // currentActiveMenu: "/people/contractPeople",
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/people/contractPeople/index.vue")
    }

  ]
};

export default system;
