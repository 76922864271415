import type { AppRouteModule } from "/@/router/types";

import { LAYOUT } from "/@/router/constant";
import { t } from "/@/hooks/web/useI18n";

const system: AppRouteModule = {
  path: "/esign",
  name: "Esign",
  component: LAYOUT,
  redirect: "/esign/template",
  meta: {
    orderNo: 30,
    // icon: 'ion:aperture-outline',
    /** 2022/3/14
     *作者:pzt
     *内容:
     **/
    // icon: "ant-design:usergroup-add-outlined",
    title: t("routes.menus.esign.esign"),
    roles: ["admin", "Esign",
      "Template"
    ]
  },
  children: [
    {
      path: "template",
      name: "Template",
      meta: {
        title: t("routes.menus.esign.template"),
        ignoreKeepAlive: false,
        roles: ["admin", "Template"]
      },
      component: () => import("/@/views/esign/template/index.vue")
    },
    {
      path: "keyDetail/:id?/:name?",
      name: "KeyDetail",
      meta: {
        hideMenu: true,
        title: t("routes.menus.esign.keyDetail"),
        roles: ["admin", "Template"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/esign/template"
      },
      component: () => import("/@/views/esign/template/KeyDetail.vue")
    }
  ]
};

export default system;
