import type { AppRouteModule } from "/@/router/types";

import { LAYOUT } from "/@/router/constant";
import { t } from "/@/hooks/web/useI18n";

const system: AppRouteModule = {
  path: "/report",
  name: "Report",
  component: LAYOUT,
  redirect: "/report/invoiceAmount",
  meta: {
    orderNo: 60,
    // icon: 'ion:aperture-outline',
    roles: ["admin",
      "InvoiceAmount",
      "Profits",
      "EnterpriseAmount",
      "SalesAmount"
    ],
    // icon: "ant-design:account-book-outlined",
    title: t("routes.menus.report.report")
  },
  children: [
    {
      path: "invoiceAmount",
      name: "InvoiceAmount",
      meta: {
        title: t("routes.menus.report.invoiceAmount"),
        ignoreKeepAlive: false,
        roles: ["admin", "InvoiceAmount"]
      },
      component: () => import("/@/views/report/invoiceAmount/index.vue")
    },
    {
      path: "profits",
      name: "Profits",
      meta: {
        title: t("routes.menus.report.profits"),
        ignoreKeepAlive: false,
        roles: ["admin", "Profits"]
      },
      component: () => import("/@/views/report/profits/index.vue")
    },
    {
      path: "enterpriseAmount",
      name: "EnterpriseAmount",
      meta: {
        title: t("routes.menus.report.enterpriseAmount"),
        ignoreKeepAlive: false,
        roles: ["admin", "EnterpriseAmount"]
      },
      component: () => import("/@/views/report/enterpriseAmount/index.vue")
    },
    {
      path: "salesAmount",
      name: "SalesAmount",
      meta: {
        title: t("routes.menus.report.salesAmount"),
        ignoreKeepAlive: false,
        roles: ["admin", "SalesAmount"]
      },
      component: () => import("/@/views/report/salesAmount/index.vue")
    }
  ]
};

export default system;
