import type { RouteLocationNormalized, RouteRecordNormalized } from "vue-router";
import type { App, Plugin } from "vue";

import { unref } from "vue";
import { isObject } from "/@/utils/is";

export const noop = () => {
};

/** 2022/4/27
 *作者:pzt
 *内容:属性是undef就删除
 **/
export function removePropertyOf(obj) {
  Object.keys(obj).forEach(item => {
    if (obj[item] === null || obj[item] === undefined || obj[item] === "") delete obj[item];
  });
  return obj;
};

/** 2022/3/30
 *作者:pzt
 *内容:过滤树节点id
 **/
export function filterSelectTreeId(arr1, keys) {
  let data: any = { id: "", arr: [] };
  try {
    arr1.forEach((arr2) => {
      if (arr2.children.length > 0) {
        arr2.children.forEach((arr3) => {
          if (arr3.children.length > 0) {
            arr3.children.forEach((item) => {
              if (keys.includes(item.id)) {
                data.id = arr2.id;
                data.arr.push(item.id);
                // throw new Error('12');
              }
            });
          }
        });
      }
    });
  } catch (e) {
    console.log(e);
  }
  return data;
}

export function filterSelectTreeAnyId(arr1, keys, id) {
  let data: any = { id: "", arr: [] };
  try {
    arr1.forEach((arr2) => {
      if (arr2.children.length > 0) {
        arr2.children.forEach((arr3) => {
          if (arr3.children.length > 0) {
            arr3.children.forEach((item) => {
              if (keys.includes(item[id])) {
                data.id = arr2[id];
                data.arr.push(item[id]);
                // throw new Error('12');
              }
            });
          }
        });
      }
    });
  } catch (e) {
    console.log(e);
  }
  return data;
}

export function filterSelectTree2AnyId(arr1, keys, id) {
  let data: any = { id: "", arr: [] };
  try {
    arr1.forEach((arr2) => {
      if (arr2.children.length > 0) {
        arr2.children.forEach((item) => {
          if (keys.includes(item[id])) {
            data.id = arr2[id];
            data.arr.push(item[id]);
          }
        });
      }
    });
  } catch (e) {
    console.log(e);
  }
  return data;
}

// arr:全部数组、selects：当前选中的数据、key：过滤的属性、data：结果
export function getTreeId(arr, selects, key, data) {
  arr.forEach(item => {
    if (item.children.length > 0) {
      data = getTreeId(item.children, selects, key, data);
    } else {
      if (selects.includes(item[key])) {
        data.push(item[key]);
      }
    }
  });
  return data;
}

/**
 * @description:  Set ui mount node
 */
export function getPopupContainer(node?: HTMLElement): HTMLElement {
  return (node?.parentNode as HTMLElement) ?? document.body;
}

/**
 * Add the object as a parameter to the URL
 * @param baseUrl url
 * @param obj
 * @returns {string}
 * eg:
 *  let obj = {a: '3', b: '4'}
 *  setObjToUrlParams('www.baidu.com', obj)
 *  ==>www.baidu.com?a=3&b=4
 */
export function setObjToUrlParams(baseUrl: string, obj: any): string {
  let parameters = "";
  for (const key in obj) {
    parameters += key + "=" + encodeURIComponent(obj[key]) + "&";
  }
  parameters = parameters.replace(/&$/, "");
  return /\?$/.test(baseUrl) ? baseUrl + parameters : baseUrl.replace(/\/?$/, "?") + parameters;
}

export function deepMerge<T = any>(src: any = {}, target: any = {}): T {
  let key: string;
  for (key in target) {
    src[key] = isObject(src[key]) ? deepMerge(src[key], target[key]) : (src[key] = target[key]);
  }
  return src;
}

export function openWindow(
  url: string,
  opt?: { target?: TargetContext | string; noopener?: boolean; noreferrer?: boolean }
) {
  const { target = "__blank", noopener = true, noreferrer = true } = opt || {};
  const feature: string[] = [];

  noopener && feature.push("noopener=yes");
  noreferrer && feature.push("noreferrer=yes");

  window.open(url, target, feature.join(","));
}

// dynamic use hook props
export function getDynamicProps<T, U>(props: T): Partial<U> {
  const ret: Recordable = {};

  Object.keys(props).map((key) => {
    ret[key] = unref((props as Recordable)[key]);
  });

  return ret as Partial<U>;
}

export function getRawRoute(route: RouteLocationNormalized): RouteLocationNormalized {
  if (!route) return route;
  const { matched, ...opt } = route;
  return {
    ...opt,
    matched: (matched
      ? matched.map((item) => ({
        meta: item.meta,
        name: item.name,
        path: item.path
      }))
      : undefined) as RouteRecordNormalized[]
  };
}

export const withInstall = <T>(component: T, alias?: string) => {
  const comp = component as any;
  comp.install = (app: App) => {
    app.component(comp.name || comp.displayName, component);
    if (alias) {
      app.config.globalProperties[alias] = component;
    }
  };
  return component as T & Plugin;
};

export function filterObj(arr, key) {
  // 通过obj的属性来判断数组中的对象是否唯一
  var obj = {};
  // 对比唯一的属性
  return arr.reduce((prev, cur) => {
    // 三目运算，对象中没有才push
    obj[cur[key]] ? "" : (obj[cur[key]] = true && prev.push(cur));
    return prev;
    //第二个参数初始值是空数组。
  }, []);
}


/**
 * @description 格式化金额
 * @param number：要格式化的数字
 * @param decimals：保留几位小数 默认0位
 * @param decPoint：小数点符号 默认.
 * @param thousandsSep：千分位符号 默认为,
 */
/*export const formatMoney = (number, decimals = 2, decPoint = ".", thousandsSep = ",", symbol = "") => {
  number = (number + "").replace(/[^0-9+-Ee.]/g, "");
  let n = !isFinite(+number) ? 0 : +number;
  let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
  let sep = (typeof thousandsSep === "undefined") ? "," : thousandsSep;
  let dec = (typeof decPoint === "undefined") ? "." : decPoint;
  let s: any = "";
  let toFixedFix = function(n, prec) {
    let k = Math.pow(10, prec);
    return "" + Math.floor(n * k) / k;
  };
  s = (prec ? toFixedFix(n, prec) : "" + Math.round(n)).split(".");
  let re = /(-?\d+)(\d{3})/;
  while (re.test(s[0])) {
    s[0] = s[0].replace(re, "$1" + sep + "$2");
  }
  if ((s[1] || "").length < prec) {
    s[1] = s[1] || "";
    s[1] += new Array(prec - s[1].length + 1).join("0");
  }
  return `${symbol} ${s.join(dec)}`;
};*/
// 金额格式化
export const formatMoney = (number, decimals = 2) => {
  if (number == 0) return 0;
  if (!number) return;
  return new Intl.NumberFormat("zh-CN", {
    // 小数点最小
    minimumFractionDigits: decimals,
    // 小数点最大
    maximumFractionDigits: 20
  }).format(number);
};
// 判断是否是图片格式的
export const isImage = (url: string) => {
  /* console.log(url, "url");
   let isShow = false;
   if (url.indexOf(".png") > 0 || url.indexOf(".jpg") > 0 || url.indexOf(".jpeg") > 0 || url.indexOf(".gif") > 0) {
     isShow = true;
   }
   console.log(isShow, "isShow");*/
  return /\.(jpg|jpeg|png|gif)$/i.test(url);
};
