import type { AppRouteModule } from "/@/router/types";

import { LAYOUT } from "/@/router/constant";
import { t } from "/@/hooks/web/useI18n";

const system: AppRouteModule = {
  path: "/business",
  name: "Business",
  component: LAYOUT,
  redirect: "/business/insurance",
  meta: {
    orderNo: 10,
    // icon: 'ion:aperture-outline',
    roles: ["admin",
      "Salary",
      "SalaryDetail",
      "SalaryTotal",
      "Invoice",
      "Ticket",
      "StandBook",
      "Drawback",
      "BusinessReportChart",
      "BusinessReport"
    ],
    // icon: "ant-design:account-book-outlined",
    title: t("routes.menus.business.business")
  },
  children: [
    {
      path: "ticket",
      name: "Ticket",
      meta: {
        title: t("routes.menus.business.ticket"),
        ignoreKeepAlive: false,
        roles: ["admin", "Ticket"]
      },
      component: () => import("/@/views/business/ticket/index.vue")
    },
    {
      path: "ticketDetail",
      name: "ticketDetail",
      meta: {
        hideMenu: true,
        title: t("routes.menus.business.ticket"),
        roles: ["admin", "Ticket"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/business/ticket"
      },
      component: () => import("/@/views/business/ticket/detail/index.vue")
    },
    /*{
      path: "drawbackDetail",
      name: "DrawbackDetail",
      meta: {
        hideMenu: true,
        title: t("routes.menus.business.drawbackDetail"),
        roles: ["admin", "Ticket"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/business/ticket"
      },
      component: () => import("/@/views/business/ticket/DrawbackDetail.vue")
    },*/
    {
      path: "salary",
      name: "Salary",
      meta: {
        title: t("routes.menus.business.salary"),
        ignoreKeepAlive: false,
        roles: ["admin", "Salary"]
      },
      component: () => import("/@/views/business/salary/index.vue")
    },
    {
      path: "salaryDet",
      name: "SalaryDet",
      meta: {
        title: t("routes.menus.business.salary"),
        ignoreKeepAlive: false,
        showMenu: false,
        hideMenu: true,
        currentActiveMenu: "/business/standBook",
        roles: ["admin", "Salary"]
      },
      component: () => import("/@/views/business/salary/index.vue")
    },
    {
      path: "salaryDetail/:id/:name?/:status?",
      name: "SalaryDetail",
      meta: {
        hideMenu: true,
        title: t("routes.menus.business.salaryDetail"),
        roles: ["admin", "Salary"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/business/salary"
      },
      component: () => import("/@/views/business/salary/SalaryDetail.vue")
    },
    /*{
      path: "salaryTotal",
      name: "SalaryTotal",
      meta: {
        title: t("routes.menus.business.salaryTotal"),
        ignoreKeepAlive: false,
        roles: ["admin", "SalaryTotal"]
      },
      component: () => import("/@/views/business/salaryTotal/index.vue")
    },
    {
      path: "salaryTotalDetail/:id/:name/:status",
      name: "SalaryTotalDetail",
      meta: {
        hideMenu: true,
        title: t("routes.menus.business.salaryDetail"),
        roles: ["admin", "SalaryTotal"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/business/salaryTotal"
      },
      component: () => import("/@/views/business/salaryTotal/SalaryDetail.vue")
    },*/
    {
      path: "invoice",
      name: "Invoice",
      meta: {
        title: t("routes.menus.people.invoice"),
        roles: ["admin", "Invoice"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/people/invoice/index.vue")
    },
    {
      path: "invoiceDet",
      name: "InvoiceDet",
      meta: {
        title: t("routes.menus.people.invoice"),
        roles: ["admin", "Invoice"],
        ignoreKeepAlive: false,
        showMenu: false,
        hideMenu: true,
        currentActiveMenu: "/business/standBook"
      },
      component: () => import("/@/views/people/invoice/index.vue")
    },
    {
      path: "standBook",
      name: "StandBook",
      meta: {
        title: t("routes.menus.people.standBook"),
        ignoreKeepAlive: false,
        roles: ["admin", "StandBook"]
      },
      component: () => import("/@/views/people/standBook/index.vue")
    },
    {
      path: "drawback",
      name: "Drawback",
      meta: {
        title: t("routes.menus.business.drawback"),
        roles: ["admin", "Drawback"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/business/drawback/index.vue")
    },
    {
      path: "businessReport",
      name: "BusinessReport",
      meta: {
        title: t("routes.menus.business.businessReport"),
        roles: ["admin", "BusinessReport"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/business/businessReport/index.vue")
    },
    {
      path: "businessReportChart",
      name: "BusinessReportChart",
      meta: {
        title: t("routes.menus.business.businessReportChart"),
        roles: ["admin", "BusinessReportChart"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/business/businessReportChart/index.vue")
    }
  ]
};

export default system;
