import type { AppRouteModule } from "/@/router/types";

import { LAYOUT } from "/@/router/constant";
import { t } from "/@/hooks/web/useI18n";

const system: AppRouteModule = {
  path: "/invoiceProcess",
  name: "invoiceProcess",
  component: LAYOUT,
  redirect: "/invoiceProcess/return",
  meta: {
    orderNo: 1,
    // icon: 'ion:aperture-outline',
    /*
    *
    * 按钮-订单-新增	btnInvoiceProcessOrderAdd
    * 按钮-订单-编辑	btnInvoiceProcessOrderEdit
    * 按钮-订单-驳回	btnInvoiceProcessOrderReject
    * 按钮-订单-审核	btnInvoiceProcessOrderApprove
    * 按钮-订单-撤销	btnInvoiceProcessOrderCancel
    * */
    roles: [
      "admin",
      "invoiceProcessContract",
      "invoiceProcessInvoice",
      "invoiceProcessFee",
      "invoiceProcessRate",
      "invoiceProcessReturn",
      "invoiceProcessOrder"
    ],
    // icon: "ant-design:account-book-outlined",
    title: t("routes.menus.invoiceProcess.invoiceProcess")
  },
  children: [
    {
      path: "contract",
      name: "invoiceProcessContract",
      meta: {
        title: t("routes.menus.invoiceProcess.contract"),
        roles: ["admin", "invoiceProcessContract"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/invoiceProcess/contract/index.vue")
    },
    {
      path: "rate",
      name: "invoiceProcessRate",
      meta: {
        title: t("routes.menus.invoiceProcess.rate"),
        roles: ["admin", "invoiceProcessRate"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/invoiceProcess/rate/index.vue")
    },
    {
      path: "order",
      name: "invoiceProcessOrder",
      meta: {
        title: t("routes.menus.invoiceProcess.order"),
        roles: ["admin", "invoiceProcessOrder"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/invoiceProcess/order/index.vue")
    },
    {
      path: "invoice",
      name: "invoiceProcessInvoice",
      meta: {
        title: t("routes.menus.invoiceProcess.invoice"),
        roles: ["admin", "invoiceProcessInvoice"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/invoiceProcess/invoice/index.vue")
    },
    /*{
      path: "fee",
      name: "invoiceProcessFee",
      meta: {
        title: t("routes.menus.invoiceProcess.fee"),
        roles: ["admin", "invoiceProcessFee"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/invoiceProcess/fee/index.vue")
    },*/
    {
      path: "return",
      name: "invoiceProcessReturn",
      meta: {
        title: t("routes.menus.invoiceProcess.return"),
        roles: ["admin", "invoiceProcessReturn"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/invoiceProcess/return/index.vue")
    }
  ]
};

export default system;
