import type { AppRouteModule } from "/@/router/types";

import { LAYOUT } from "/@/router/constant";
import { t } from "/@/hooks/web/useI18n";

const system: AppRouteModule = {
  path: "/tender",
  name: "tender",
  component: LAYOUT,
  redirect: "/tender/contractTender",
  meta: {
    orderNo: 50,
    // icon: 'ion:aperture-outline',
    /** 2022/3/14
     *作者:pzt
     *内容:
     **/
    // icon: "ant-design:usergroup-add-outlined",
    title: t("routes.menus.tender.tender"),
    roles: ["admin", "tender",
      "contractTender",
      "dataTender",
      "employeeTender",
      "houseTender",
      "vehicleTender",
      "contractInvoice",
      "spiderSearch",
      "spiderItem"
    ]
  },
  children: [
    {
      path: "contractTender",
      name: "contractTender",
      meta: {
        title: t("routes.menus.tender.contractTender"),
        roles: ["admin", "contractTender"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/tender/contractTender/index.vue")
    },
    {
      path: "dataTender",
      name: "dataTender",
      meta: {
        title: t("routes.menus.tender.dataTender"),
        ignoreKeepAlive: false,
        roles: ["admin", "dataTender"]
      },
      component: () => import("/@/views/tender/dataTender/index.vue")
    },
    {
      path: "employeeTender",
      name: "employeeTender",
      meta: {
        title: t("routes.menus.tender.employeeTender"),
        ignoreKeepAlive: true,
        roles: ["admin", "employeeTender"]
      },
      component: () => import("/@/views/tender/employeeTender/index.vue")
    },
    // 人员 类型
    {
      path: "employeeTenderType",
      name: "employeeTenderType",
      meta: {
        hideMenu: true,
        title: "证书详情",
        roles: ["admin", "employeeTender"],
        ignoreKeepAlive: false,
        showMenu: false,
        currentActiveMenu: "/tender/employeeTender"
      },
      component: () => import("/@/views/tender/employeeTenderType/index.vue")
    },
    {
      path: "houseTender",
      name: "houseTender",
      meta: {
        title: t("routes.menus.tender.houseTender"),
        ignoreKeepAlive: false,
        roles: ["admin", "houseTender"]
      },
      component: () => import("/@/views/tender/houseTender/index.vue")
    },
    {
      path: "vehicleTender",
      name: "vehicleTender",
      meta: {
        title: t("routes.menus.tender.vehicleTender"),
        ignoreKeepAlive: false,
        roles: ["admin", "vehicleTender"]
      },
      component: () => import("/@/views/tender/vehicleTender/index.vue")
    },
    {
      path: "contractInvoice",
      name: "ContractInvoice",
      meta: {
        title: t("routes.menus.tender.contractInvoice"),
        ignoreKeepAlive: false,
        roles: ["admin", "contractInvoice"]
      },
      component: () => import("/@/views/tender/contractInvoice/index.vue")
    },
    {
      path: "spiderSearch",
      name: "spiderSearch",
      meta: {
        title: t("routes.menus.tender.spiderSearch"),
        ignoreKeepAlive: false,
        roles: ["admin", "spiderSearch"]
      },
      component: () => import("/@/views/tender/spiderSearch/index.vue")
    },
    {
      path: "spiderItem",
      name: "spiderItem",
      meta: {
        title: t("routes.menus.tender.spiderItem"),
        ignoreKeepAlive: false,
        roles: ["admin", "spiderItem"]
      },
      component: () => import("/@/views/tender/spiderItem/index.vue")
    }

  ]
};

export default system;
