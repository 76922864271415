import type { AppRouteModule } from "/@/router/types";

import { LAYOUT } from "/@/router/constant";
import { t } from "/@/hooks/web/useI18n";

const system: AppRouteModule = {
  path: "/jinKe",
  name: "jinKe",
  component: LAYOUT,
  redirect: "/jinKe/return",
  meta: {
    orderNo: 10,
    // icon: 'ion:aperture-outline',

    roles: [
      "admin",
      "jinKeClerkPay",
      "jinKeClerkClient",
      "jinKeClerkPayTax"
    ],
    // icon: "ant-design:account-book-outlined",
    title: t("routes.menus.jinKe.jinKe")
  },
  children: [
    {
      path: "clerkPay",
      name: "jinKeClerkPay",
      meta: {
        title: t("routes.menus.jinKe.clerkPay"),
        roles: ["admin", "jinKeClerkPay"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/jinKe/clerkPay/index.vue")
    },
    {
      path: "clerkPayTax",
      name: "clerkPayTax",
      meta: {
        title: t("routes.menus.jinKe.clerkPayTax"),
        roles: ["admin", "jinKeClerkPayTax"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/jinKe/clerkPayTax/index.vue")
    },
    {
      path: "clerkClient",
      name: "clerkClient",
      meta: {
        title: t("routes.menus.jinKe.clerkClient"),
        roles: ["admin", "jinKeClerkClient"],
        ignoreKeepAlive: false
      },
      component: () => import("/@/views/jinKe/clerkClient/index.vue")
    }

  ]
};

export default system;
